import { Injectable } from '@angular/core';
import * as countries from '../../../assets/files/countries.json';

export interface Country {
  name: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public getCountries(): Country[] {
    return JSON.parse(JSON.stringify(countries)).default;
  }

  constructor() { }

}
