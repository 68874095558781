import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { FormService, Country } from '../appointment-form/service/form.service';

@Component({
  selector: 'app-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.scss']
})
export class AppointmentFormComponent implements OnInit {

  // public form: FormGroup = null;
  public countries: Country[];
  public companySizes: string[] = [
    '1-5 Empleados',
    '5-10 Empleados',
    '10-50 Empleados',
    '50-100 Empleados',
    'Mas de 100 Empleados'
  ];
  public defaultCountry = 'CR';

  // public get name(): AbstractControl {
  //   return this.form.get('name');
  // }

  // public get email(): AbstractControl {
  //   return this.form.get('email');
  // }

  // public get companyName(): AbstractControl {
  //   return this.form.get('companyName');
  // }

  // public get position(): AbstractControl {
  //   return this.form.get('position');
  // }

  // public get companySize(): AbstractControl {
  //   return this.form.get('companySize');
  // }

  // public get country(): AbstractControl {
  //   return this.form.get('country');
  // }

  // public get comment(): AbstractControl {
  //   return this.form.get('comment');
  // }

  // private buildForm(): FormGroup {
  //   return this.formBuilder.group({
  //     name: [''],
  //     email: [''],
  //     companyName: [''],
  //     position: [''],
  //     companySize: ['1-5 Empleados'],
  //     country: ['CR'],
  //     comment: [''],
  //   });
  // }

  public ngOnInit() {
    // this.form = this.buildForm();
    this.countries = this.formService.getCountries();
  }

  constructor(
    // private formBuilder: FormBuilder,
    private formService: FormService
  ) { }

}
