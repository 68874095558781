import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
    selector: 'app-form-capture-leads',
    templateUrl: './form-capture-leads.component.html',
    styleUrls: ['./form-capture-leads.component.scss'],
})
export class FormCaptureLeadsComponent {

    public leadsForm = new FormGroup({
        name: new FormControl(''),
        phone: new FormControl(''),
        email: new FormControl(''),
        company: new FormControl(''),
        industry: new FormControl(''),
        omnicanal: new FormControl(''),
        bots: new FormControl(''),
        adminConversaciones: new FormControl(false),
        whatsappMultiagente: new FormControl(false),
        metrics: new FormControl(false),
        howToBeContacted: new FormControl('WA')
    });

    public showSuccessMessage = false;

    @Output() submitedForm = new EventEmitter<boolean>();

    public onSubmit(): void {

        const apiUrl = 'https://hook.us1.make.com/z8t6kpbhjrn0cpxmapsx26vslk8g65ya';
        const data = {
            'name': this.leadsForm.value.name,
            'email': this.leadsForm.value.email,
            'phone': this.leadsForm.value.phone,
            'company': this.leadsForm.value.company,
            'industry': this.leadsForm.value.industry,
            'omnicanal': this.leadsForm.value.omnicanal,
            'bots': this.leadsForm.value.bots,
            'features': '',
            'howToBeContacted': this.leadsForm.value.howToBeContacted
        };

        if (this.leadsForm.value.adminConversaciones) {
            data.features += 'administrar conversaciones ';
        }
        if (this.leadsForm.value.whatsappMultiagente) {
            data.features += 'whatsApp multiagente ';
        }
        if (this.leadsForm.value.metrics) {
            data.features += 'metricas ';
        }
        if (this.leadsForm.value.omnicanal) {
            data.features += 'omnicanal ';
        }
        if (this.leadsForm.value.bots) {
            data.features += 'bots ';
        }

        const url = apiUrl + '?name=' + data.name
            + '&email=' + data.email
            + '&phone=' + data.phone
            + '&company=' + data.company
            + '&industry=' + data.industry
            + '&features=' + data.features
            + '&howToBeContacted=' + data.howToBeContacted;

        this.http.get(url, { responseType: 'text' }).subscribe(() => {
            this.leadsForm.reset();
            this.showSuccessMessage = true;
        });

        this.submitedForm.emit(true);
    }

    public trackEvent(label: string, value: string) {
        gtag('event', 'CLICK FORM LEADS', {
            eventCategory: 'CLICK',
            eventLabel: label,
            eventAction: 'CLICK',
            eventValue: value,
        });
    }

    constructor(
        private http: HttpClient
    ) { }

}
