import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewLandingPageComponent } from './new-landing-page/new-landing-page.component';


const routes: Routes = [
  { path: '', component: NewLandingPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
