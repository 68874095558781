import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlowDataComponent } from './flow-data/flow-data.component';
import { AppointmentFormComponent } from './appointment-form/appointment-form.component';
import { NewLandingPageComponent } from './new-landing-page/new-landing-page.component';
import { FormCaptureLeadsComponent } from './form-capture-leads/form-capture-leads.component';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BotTeamSectionComponent } from './bot-team-section/bot-team-section.component';
import { IntegrationSectionComponent } from './integration-section/integration-section.component';
import { PlanCompareTableComponent } from './plan-compare-table/plan-compare-table.component';
import { CardCarouselComponent } from './card-carousel/card-carousel.component';
import { CommentsCarouselComponent } from './comments-carousel/comments-carousel.component';
import { CommentsCardsComponent } from './comments-cards/comments-cards.component';
@NgModule({
  declarations: [
    AppComponent,
    FlowDataComponent,
    NewLandingPageComponent,
    FormCaptureLeadsComponent,
    AppointmentFormComponent,
    BotTeamSectionComponent,
    IntegrationSectionComponent,
    PlanCompareTableComponent,
    CardCarouselComponent,
    CommentsCarouselComponent,
    CommentsCardsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModalModule,
    NgbTooltipModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [FormCaptureLeadsComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
