import { Component} from '@angular/core';

@Component({
  selector: 'app-comments-cards',
  templateUrl: './comments-cards.component.html',
  styleUrls: ['./comments-cards.component.scss']
})

export class CommentsCardsComponent {
  constructor() {}
}
