import { Component } from '@angular/core';

@Component({
  selector: 'app-bot-team-section',
  templateUrl: './bot-team-section.component.html',
  styleUrls: ['./bot-team-section.component.scss']
})
export class BotTeamSectionComponent {

  public botTeam = [{
    avatar: 'assets/images/BasicBot_Avatar.png',
    name: 'Asistente Básico',
    description: 'Utiliza <strong>inteligencia artificial</strong> para contestar las preguntas frecuentes de tus clientes.',
    videoLink: '',
  }, {
    avatar: 'assets/images/ManageBot_Avatar.png',
    name: 'Asistente de Autogestión',
    description: 'Permite que tus <strong>clientes auto-gestionen</strong> sus consultas por medio de menús interactivos.',
    videoLink: '',
  }, {
    avatar: 'assets/images/LeadBot_Avatar.png',
    name: 'Asistente de Captura',
    description: '<strong>Captura la información de tus clientes </strong>'
    + 'automáticamente para pedidos, cotizaciones, reservaciones, entre otros.',
    videoLink: '',
  }, {
    avatar: 'assets/images/SincBot_Avatar.png',
    name: 'Asistente de Sincronización',
    description: '<strong>Sincroniza los contactos</strong> de Socialdesk<sup>®</sup> con tu CRM.',
    videoLink: '',
  }];

  constructor() { }

}
