import { Component } from '@angular/core';

@Component({
  selector: 'app-integration-section',
  templateUrl: './integration-section.component.html',
  styleUrls: ['./integration-section.component.scss']
})
export class IntegrationSectionComponent {

  constructor() { }

}
