import { Component} from '@angular/core';

@Component({
  selector: 'app-comments-carousel',
  templateUrl: './comments-carousel.component.html',
  styleUrls: ['./comments-carousel.component.scss']
})

export class CommentsCarouselComponent {
  public seeMore = false;

  public onViewMoreToggle() {
    this.seeMore = !this.seeMore;
  }

  constructor() {}
}
