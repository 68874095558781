import { Component } from '@angular/core';

interface FlowData {
  title: string;
  description: string;
}
@Component({
  selector: 'app-flow-data',
  templateUrl: './flow-data.component.html',
  styleUrls: ['./flow-data.component.scss']
})
export class FlowDataComponent {

  flowData: FlowData[] = [
    {
      title: 'Atiende a tus clientes 24/7',
      description: `Socialdesk<sup>®</sup> te permite contar con un colaborador extra atendiendo las consultas
                    de tus clientes en las redes sociales sin importar el día o la hora`
    },
    {
      title: 'Reduce tiempos de respuesta',
      description: 'El sistema puede responder de manera automatica el 80% de las preguntas que ingresan al inbox de tu empresa'
    },
    {
      title: 'Previene errores',
      description: 'El sistema brinda ayuda con opciones inteligentes  para la toma de decisiones y así se previenen errores'
    },
    {
      title: 'Aumenta la capacidad de tus colaboladores',
      description: `Con Socialdesk<sup>®</sup> tus colaboradores pueden ser hasta <strong>10x veces más eficientes</strong>`
    }
  ];

}
