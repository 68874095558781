import { Component} from '@angular/core';

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss']
})
export class CardCarouselComponent {
  public seeMore = false;
  public onViewMoreToggle() {
    this.seeMore = !this.seeMore;
  }

  constructor() {}
}
