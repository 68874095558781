import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var window: any;
// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
  selector: 'app-new-landing-page',
  templateUrl: './new-landing-page.component.html',
  styleUrls: ['./new-landing-page.component.scss'],
})
export class NewLandingPageComponent implements OnInit {
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  public getOffsetTop(element): number {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  }

  public scrollTo(el: HTMLElement, linkName: string): void {
    gtag('event', 'CLICK ' + linkName.toUpperCase(), {
      eventCategory: 'CLICK',
      eventLabel: 'CLICK ON MENU',
      eventAction: 'CLICK',
      eventValue: linkName,
    });
    window.scroll({
      top: this.getOffsetTop(el) - 100,
      left: 0,
      behavior: 'smooth',
    });
  }

  public showFacebookChat(): void {
    window.FB.CustomerChat.showDialog();
  }

  public trackEvent(label: string, value: string) {
    gtag('event', 'CLICK SIGNUP', {
      eventCategory: 'CLICK',
      eventLabel: label,
      eventAction: 'CLICK',
      eventValue: value,
    });
  }

  public open(content, size: string = 'lg') {
    this.modalService.open(content, { size });
  }
}
