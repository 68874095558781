import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-compare-table',
  templateUrl: './plan-compare-table.component.html',
  styleUrls: ['./plan-compare-table.component.scss']
})
export class PlanCompareTableComponent implements OnInit {

  public plans = [{
    name: 'Plan Básico',
    anualPrice: '63,2',
    monthPrice: '$79',
    numIncludedUsers: '8 (Usuario adicional $15 + IVA)',
    conversationSearchType: 'Por nombre o teléfono',
    monthlyConversations: 1000,
    customChannelManagement: true,
    conversationReport: true,
    multipleAccounts: true,
    scheduledMessages: true,
    artificialIntelligenceResponse: 'Con palabras clave',
    automatedMessageAllocation: '',
    selfManagementMenu: '2 niveles cada uno con hasta 5 opciones',
    automaticAssignmentConversations: true,
    collectionCustomerInformation: '1 formulario con hasta 3 preguntas',
    orderCapture: '',
    bookinCapture: '',
    contactSync: '✅ HubSpot y ActiveCampaign',
    conversationSupport: '6 meses',
    zapierIntegration: true
  },
  {
    name: 'Plan Avanzado',
    anualPrice: '199,2',
    monthPrice: '$249',
    numIncludedUsers: '12 (Usuario adicional $25 + IVA)',
    conversationSearchType: 'Por palabras claves (Pronto)',
    monthlyConversations: 1000,
    customChannelManagement: true,
    conversationReport: true,
    multipleAccounts: true,
    scheduledMessages: true,
    artificialIntelligenceResponse: 'Inteligencia artificial generativa ChatGPT (Pronto)',
    automatedMessageAllocation: true,
    selfManagementMenu: '3 niveles cada uno con hasta 10 opciones',
    automaticAssignmentConversations: true,
    collectionCustomerInformation: '3 formularios con hasta 5 preguntas',
    orderCapture: 'Catálogo de hasta 20 productos',
    bookinCapture: true,
    contactSync: '✅ HubSpot, ActiveCampaign y CRMs a través de Make.com o Zapier',
    conversationSupport: '1 año',
    zapierIntegration: true
  }];

  public mappedData = [
    {
      key: 'numIncludedUsers',
      value: 'Usuarios incluidos.'
    },
    {
      key: 'monthlyConversations',
      value: 'Conversaciones mensuales incluidas.'
    },
    {
      key: 'customChannelManagement',
      value: 'Gestiona tus canales digitales desde un mismo lugar.'
    },
    {
      key: 'conversationReport',
      value: 'Reportes de conversaciones.'
    },
    {
      key: 'conversationSearchType',
      value: 'Búsqueda de conversaciones.'
    },
    {
      key: 'multipleAccounts',
      value: `Manejo de <b>cuentas múltiples</b>.`
    },
    {
      key: 'scheduledMessages',
      value: `Programación de mensajes por fecha y hora.`
    },
    {
      key: 'artificialIntelligenceResponse',
      value: 'Respuesta automatica de preguntas frecuentes.'
    },
    {
      key: 'selfManagementMenu',
      value: 'Menús de autogestión (IVR).'
    },
    {
      key: 'automaticAssignmentConversations',
      value: 'Asigna conversaciones de manera automática. (ACD).'
    },
    {
      key: 'collectionCustomerInformation',
      value: 'Recolecta información de tus clientes.'
    },
    {
      key: 'orderCapture',
      value: 'Toma de pedidos.'
    },
    {
      key: 'bookinCapture',
      value: 'Formulario web para la captura de reservaciones o citas.'
    },
    {
      key: 'contactSync',
      value: 'Sincronización de contactos con el CRM.'
    },
    {
      key: 'conversationSupport',
      value: 'Respaldo de conversaciones.'
    },
    {
      key: 'zapierIntegration',
      value: 'Integración con Zapier y <a target="_blank" href="https://make.com">Make.com</a>.'
    }
  ];

  public formatedTableData;

  ngOnInit() {
    this.formatedTableData = Object.keys(this.plans[0]).reduce((prev, curr) => {
      prev[curr] = this.plans.map(plan => plan[curr]);
      return { ...prev };
    }, {});
  }

  constructor() { }

}


